import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = (props) => {
  const urlParams = new URLSearchParams(props.location.search)

  const wasSuccess = urlParams.get('flash_id')
  const wasError = props.location.search.includes('errors')
  const flash = urlParams.get('flash')

  return (
    <Layout>
      <Seo title="Contact | Clove" />
      <div className={"relative bg-white py-16"}>
        <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"}>
          <h2 className="heading-primary">How can we help?</h2>

          <div className={"mt-16 grid sm:grid-cols-12 gap-16"}>
            <div className={"col-span-7"}>
              <p className="max-w-prose text-primary">
                If you would like a demo, need help with anything, or have any feedback, please reach out!
                You can always <a href="mailto:hello@clovecx.com" className="underline">email us</a>.
              </p>
            </div>

            <div className={"col-span-5"}>
              {
                wasSuccess ?
                  <div>
                    <p className="text-primary">{flash}</p>
                  </div> :
                  <div>
                    {
                      wasError ?
                        <div className="text-secondary mb-4 p-4 bg-red-50 text-red-800 border-red-300 border rounded-md">
                          Something happened and your form didn't go through. Try again or <a className="underline" href="mailto:hello@clovecx.com">email us</a>.
                        </div> :
                        null
                    }
                    {form()}
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function form() {
  return <form action="https://hub.clovecx.com/contact" method="POST" className="text-secondary">
    <input type="hidden" name="form_handler" value="email" />
    <input type="hidden" name="handler" value="bfc57fb3-c849-4bfb-972e-f4aae3098c76" />

    <label className="block">
      <div className="text-small">Name *</div>
      <input className="mt-2 form-input" required type="text" name="data[name]" />
    </label>

    <label className="block mt-4">
      <div className="text-small">Email *</div>
      <input className="mt-2 form-input" required type="email" name="data[email]" />
    </label>

    <label className="block mt-4">
      <div className="text-small">What can we help with?</div>
      <textarea className="mt-2 form-input" name="data[details]"></textarea>
    </label>

    <input type="hidden" name="clove_ic" value="0" />
    <Helmet>
      <script type="text/javascript" src="https://public-cdn.cloveapp.io/clove-ic.js"></script>
    </Helmet>

    <div className="mt-6">
      <button className="button-primary w-full" type="submit" value="Request a Demo">Send Message</button>
    </div>
  </form>
}

export default ContactPage
